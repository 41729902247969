<style>.exitRow {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.25rem;
  padding-top: 1rem;
}</style>

<div class="exitRow">
  <div class="text-saffron w-8">
    <svg style="transform: none;" viewBox="0 0 24 24">
      <polygon fill="currentColor" points="0,12 24,0 24,24" />
    </svg>
  </div>

  <div class="text-saffron w-8">
    <svg style="transform: none;" viewBox="0 0 24 24">
      <polygon fill="currentColor" points="0,0 0,24 24,12" />
    </svg>
  </div>
</div>
