<script>
  import { _ } from 'svelte-i18n';

  import HorizontalScrollButtons from '../HorizontalScrollButtons.svelte';
  import PassengerSelectItem from './PassengerSelectItem.svelte';

  export let flightNumber = null;
  export let passengers = null;
  export let selectedSeat = null;
  export let passenger = null;

  $: currentPassenger = passenger;
  $: isSelected = (pax) => {
    // If no current passenger, or no items in seat map pax (ie during initialization) nothing is selected
    if(!pax) return false
    if(!currentPassenger) return false
    
    if(pax.passengerID === currentPassenger.passengerID)
    {
      return true
    }
    else
    {
      return false
    }
  }  

  let containerRef = null;

</script>

<style>.flex-display {
  flex-wrap: wrap;
  flex-direction: row;
}

.passenger-select-box {
  max-height: 210px;
  overflow-y: auto;
}</style>

<div class="passengerSelect">
  <div class="flex justify-between items-baseline">
    <h3 class="italic">
      {#if passengers.length > 1}
        {$_('seatMap.selectPassenger')}
      {:else}{$_('seatMap.passenger')}{/if}
    </h3>
  </div>

  <div class="relative mb-3 passenger-select-box">
    <div
      class="border-b border-t border-gold-100 overflow-x-auto
      mb-px"
      bind:this={containerRef}
    >
      <div class="flex">
        <ul class="flex flex-display">
          {#each passengers as passenger}
            {#if passenger.passengerType !== 'INFANT'}
              <PassengerSelectItem
                {flightNumber}
                {passenger}
                isCurrentlySelectedPassenger={isSelected(passenger)}
                {selectedSeat}
                on:passengerChange
              />
            {/if}
          {/each}
        </ul>
      </div>
    </div>

    <HorizontalScrollButtons {containerRef} />
  </div>
</div>
