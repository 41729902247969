<script>
  /** Specify the value of the radio button */
  export let value = '';

  /** Set to `true` to check the radio button */
  export let checked = false;

  /** Set to `true` to disable the radio button */
  export let disabled = false;

  /** Set to `true` to mark the field as required */
  export let required = false;

  /**
   * Specify the label position
   * @type {"right" | "left"}
   */
  export let labelPosition = 'right';

  /** Specify the label text */
  export let labelText = '';

  /** Set to `true` to visually hide the label text */
  export let hideLabel = false;

  /** Set an id for the input element */
  export let id = 'ccs-' + Math.random().toString(36);

  /**
   * Specify a name attribute for the radio button input
   * @type {string}
   */
  export let name = undefined;

  /** Obtain a reference to the input HTML element */
  export let ref = null;

  import { getContext } from 'svelte';
  import { readable } from 'svelte/store';

  const { add, update, selectedValue, groupName, groupRequired } = getContext(
    'RadioButtonGroup',
  ) ?? {
    groupName: readable(undefined),
    groupRequired: readable(undefined),
    selectedValue: readable(checked ? value : undefined),
  };

  if (add) {
    add({ id, checked, disabled, value });
  }

  $: checked = $selectedValue === value;
</script>

<style>/* TODO: Match EY styling */

.custom-label {
  display: inline-block;
  border-radius: 4px;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-width: 2px;
  --border-opacity: 1;
  border-color: #bd8b13;
  border-color: rgba(189, 139, 19, var(--border-opacity));
  box-shadow: 0px 7px 8px -4px rgb(189 139 19 / 20%),
      0px 12px 17px 2px rgb(189 139 19 / 14%),
      0px 5px 22px 4px rgb(189 139 19 / 12%);
}

.hide-button {
  opacity: 0;
  position: fixed;
  width: 0;
}

/* Go from white background to gold background */

input:checked + label {
  --bg-opacity: 1;
  background-color: #bd8b13;
  background-color: rgba(189, 139, 19, var(--bg-opacity));
  border-width: 2px;
  --border-opacity: 1;
  border-color: #bd8b13;
  border-color: rgba(189, 139, 19, var(--border-opacity));
}</style>

<div class="flex items-center mt-1">
  <input
    type="radio"
    class="hide-button"
    bind:this={ref}
    {id}
    name={$groupName ?? name}
    {checked}
    {disabled}
    required={$groupRequired ?? required}
    {value}
    on:change
    on:change={() => {
      if (update) {
        update(value);
      }
    }}
  />
  <label class="custom-label px-20 py-10" for={id}>
    {#if labelText || $$slots.labelText}
      <span class:bx--visually-hidden={hideLabel}>
        <slot name="labelText">
          {labelText}
        </slot>
      </span>
    {/if}
  </label>
</div>
