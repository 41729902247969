<script>
  $: buttonProps = {
    role: 'button',
    ...$$restProps,
    class: ['px-1', $$restProps.class].filter(Boolean).join(' '),
  };
</script>

<style>button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

button {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-width: 2px;
  border-color: currentColor;
  cursor: pointer;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  height: 3.75rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  user-select: none;
  font-size: 1.7rem;
  text-align: center;
  --text-opacity: 1;
  color: #4e4c4a;
  color: rgba(78, 76, 74, var(--text-opacity));
  transition-property: all;
  width: 100%;
}

button:active {
  --bg-opacity: 1;
  background-color: #bd8b13;
  background-color: rgba(189, 139, 19, var(--bg-opacity));
  font-size: 2.5rem;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  padding-top: 0;
  font-family: AltisMedium;
  transition: all 0.2s ease-out;
  transform: scaleX(1.1) scaleY(1.05);
  transform-origin: center;
}</style>

<div {...buttonProps}>
  <button on:click>
    <slot />
  </button>
</div>
