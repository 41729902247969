<script>
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher } from 'svelte';
  import { hotkey } from '../../../js/actions';

  export let currentPage;
  export let pageNumber;
  export let disablePaginationForPassportScan;
  export let isGuidedFlow;

  const dispatch = createEventDispatcher();
  const i18nKey = `app.no${pageNumber}`;

  let isActive = false;
  let isPreviousPage = false;
  $: isActive = pageNumber === currentPage;
  $: isPreviousPage = pageNumber < currentPage;
</script>

<style>button {
  background-color: transparent;
  border-width: 1px;
  --border-opacity: 1;
  border-color: #bd8b13;
  border-color: rgba(189, 139, 19, var(--border-opacity));
  display: flex;
  height: 2rem;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  border-radius: 9999px;
  --text-opacity: 1;
  color: #bd8b13;
  color: rgba(189, 139, 19, var(--text-opacity));
  width: 2rem;
}

.isActive {
  --bg-opacity: 1;
  background-color: #bd8b13;
  background-color: rgba(189, 139, 19, var(--bg-opacity));
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

button.isPreviousPage {
  color: grey;
}</style>

<button
  class:isActive
  class:isPreviousPage
  disabled={isGuidedFlow && disablePaginationForPassportScan && !isActive}
  role="button"
  use:hotkey={pageNumber}
  on:click={() => {
    dispatch('setPage', { pageNumber });
  }}
>
  {$_(i18nKey)}
</button>
