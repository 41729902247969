<script>
  import { createEventDispatcher, onMount } from 'svelte';

  export let paneHeight = null;
  export let scrollablePaneHeaderHeight = null;

  const dispatch = createEventDispatcher();

  let scrollable = null;

  onMount(() => {
    dispatch('scrollToSeat', {
      scrollable,
    });
  });
</script>

<style>.scrollablePane {
  height: 100%;
  padding-right: 1.25rem;
}

.scrollable {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.scrollable::-webkit-scrollbar {
  width: 1.25rem;
}

.scrollable::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #ededec;
  background-color: rgba(237, 237, 236, var(--bg-opacity));
  border-radius: 9999px;
}</style>

<div class="scrollablePane">
  <div
    class="scrollable"
    style="max-height: {paneHeight}rem; margin-top: {scrollablePaneHeaderHeight}rem;"
    bind:this={scrollable}
  >
    <slot />
  </div>
</div>
