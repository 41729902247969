<script context="module">
  export const ButtonKinds = Object.freeze({
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary',
  });
</script>

<script>
  import { hotkey } from '../../js/actions';

  export let disabled = false;
  export let intent = '';
  export let isArabic = false;
  export let icon = null;
  export let iconSize = '';
  export let isFull = false;
  export let kind = '';
  export let ref = null;
  export let size = '';

  $: buttonProps = {
    role: 'button',
    disabled,
    ...$$restProps,
    class: [
      'btn',
      icon && 'btn--icon',
      isArabic && 'btn--arabic',
      isFull && 'btn--full',
      kind === ButtonKinds.SECONDARY && 'btn--secondary',
      kind === ButtonKinds.TERTIARY && 'btn--tertiary',
      size === 'micro' && 'btn--micro',
      size === 'tiny' && 'btn--tiny',
      size === 'small' && 'btn--small',
      size === 'large' && 'btn--large',
      size === 'huge' && 'btn--huge',
      $$restProps.class,
    ]
      .filter(Boolean)
      .join(' '),
  };

  /**
   * Button component.
   *
   * For adding additional logic refer to
   * https://github.com/IBM/carbon-components-svelte/blob/master/src/Button/Button.svelte
   *
   * @example <caption>Example button.</caption>
   *  <Button
   *    class="mb-2"
   *    intent={VoiceIntent.NEXT}
   *    on:click={myCoolFunction}>
   *    {$_('app.next')}
   *  </Button>
   *
   * @example <caption>Example small secondary full arabic button.</caption>
   *  <Button
   *    class="mb-2"
   *    isArabic
   *    isFull
   *    intent={VoiceIntent.SKIP}
   *    kind={ButtonKinds.SECONDARY}
   *    size="small"
   *    on:click={myCoolFunction}>
   *    {$_('app.skip')}
   *  </Button>
   *
   * @example <caption>Example icon button.</caption>
   *  <Button
   *    class="mb-2"
   *    icon={ChevronRight}
   *    iconSize="small"
   *    intent={VoiceIntent.SKIP}
   *    kind={ButtonKinds.SECONDARY}
   *    on:click={myCoolFunction}
   *  />
   *
   * @type {String} class.
   * @type {Boolean} disabled.
   * @type {Boolean} isArabic.
   * @type {Boolean} isFull. - full width.
   * @type {String} intent.
   * @type {String} kind. - Use ButtonKinds.
   * @type {String} size. - small, large button etc.
   */
</script>

<style>.btn {
  --bg-opacity: 1;
  background-color: #bd8b13;
  background-color: rgba(189, 139, 19, var(--bg-opacity));
  border-width: 3px;
  --border-opacity: 1;
  border-color: #bd8b13;
  border-color: rgba(189, 139, 19, var(--border-opacity));
  font-family: AltisMedium;
  height: 4.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0.5rem;
  font-size: 1.875rem;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  width: 17rem;
  line-height: 1;
}

.btn:active:not([disabled]) {
  --bg-opacity: 1;
  background-color: #976f0f;
  background-color: rgba(151, 111, 15, var(--bg-opacity));
  --border-opacity: 1;
  border-color: #976f0f;
  border-color: rgba(151, 111, 15, var(--border-opacity));
  box-shadow: 0px 7px 8px -4px rgb(189 139 19 / 20%),
      0px 12px 17px 2px rgb(189 139 19 / 14%),
      0px 5px 22px 4px rgb(189 139 19 / 12%);
}

.btn--icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn--icon .icon {
  margin-top: -0.25rem;
  width: 5rem;
}

.btn--icon .icon--small {
  margin-top: 0;
  padding-left: 0;
  padding-right: 1.5rem;
}

:global([dir='rtl']) .btn--icon .icon--small {
  padding-left: 1.5rem;
  padding-right: 0;
}

.btn--micro {
  height: 1.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  border-radius: 0.125rem;
  font-size: 0.5rem;
  width: auto;
}

.btn--tiny {
  height: 2rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 1rem;
  width: auto;
}

.btn--small {
  height: 3.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.25rem;
  width: auto;
}

.btn--large {
  height: 7rem;
  font-size: 2.16rem;
}

.btn--huge {
  height: 10rem;
  font-size: 2.16rem;
}

.btn--full {
  width: 100%;
}

.btn--arabic {
  font-family: Arabic;
}

.btn--secondary {
  background-color: transparent;
  --text-opacity: 1;
  color: #bd8b13;
  color: rgba(189, 139, 19, var(--text-opacity));
}

.btn--secondary:active {
  --bg-opacity: 1;
  background-color: #976f0f;
  background-color: rgba(151, 111, 15, var(--bg-opacity));
  --border-opacity: 1;
  border-color: #976f0f;
  border-color: rgba(151, 111, 15, var(--border-opacity));
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.btn--tertiary {
  --bg-opacity: 1;
  background-color: #4e4c4a;
  background-color: rgba(78, 76, 74, var(--bg-opacity));
  --border-opacity: 1;
  border-color: #4e4c4a;
  border-color: rgba(78, 76, 74, var(--border-opacity));
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.btn--tertiary:active {
  --bg-opacity: 1;
  background-color: #181818;
  background-color: rgba(24, 24, 24, var(--bg-opacity));
  --border-opacity: 1;
  border-color: #181818;
  border-color: rgba(24, 24, 24, var(--border-opacity));
}

button:disabled {
  --bg-opacity: 1;
  background-color: #ededec;
  background-color: rgba(237, 237, 236, var(--bg-opacity));
  --border-opacity: 1;
  border-color: #ededec;
  border-color: rgba(237, 237, 236, var(--border-opacity));
  --text-opacity: 1;
  color: #4e4c4a;
  color: rgba(78, 76, 74, var(--text-opacity));
}</style>

<button
  bind:this={ref}
  {...buttonProps}
  on:click
  on:mouseover
  on:mouseenter
  on:mouseleave
  use:hotkey
  {intent}
>
  {#if icon}
    <div class="icon" class:icon--small={iconSize === 'small'}>
      <svelte:component this={icon} />
    </div>
  {/if}
  <slot />
</button>
