<script>
  export let handler;
  export let active;
</script>

<style>.active {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  --bg-opacity: 1;
  background-color: #bd8b13;
  background-color: rgba(189, 139, 19, var(--bg-opacity));
}

.notActive {
  --text-opacity: 1;
  color: #bd8b13;
  color: rgba(189, 139, 19, var(--text-opacity));
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}</style>

<button
  class="capitalize rounded-1 border-gold-100 border-1 px-4 py-2"
  class:active
  class:notActive={!active}
  on:click={handler}
>
  <slot />
</button>
