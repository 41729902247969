<script>
  import { hotkey } from '../../js/actions';

  export let intent = '';
  export let kind = '';

  $: buttonProps = {
    intent,
    role: 'button',
    ...$$restProps,
    class: [
      'keypadButton',
      kind === 'wide' && 'keypadButton--wide',
      $$restProps.class,
    ]
      .filter(Boolean)
      .join(' '),
  };
</script>

<style>.keypadButton {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-width: 1px;
  border-color: currentColor;
  font-family: AltisMedium;
  height: 6.5rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  font-size: 3.5rem;
  --text-opacity: 1;
  color: #4e4c4a;
  color: rgba(78, 76, 74, var(--text-opacity));
  text-transform: uppercase;
  width: 6.25rem;
}

.keypadButton:focus {
  --bg-opacity: 1;
  background-color: #bd8b13;
  background-color: rgba(189, 139, 19, var(--bg-opacity));
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  transform: scale(1.1);
}

.keypadButton--wide {
  font-size: 2.25rem;
  width: 13.5rem;
}</style>

<button {...buttonProps} on:click use:hotkey>
  <slot />
</button>
