<script>
  import { _ } from 'svelte-i18n';
  import { booking } from '../../../js/stores';

  const displayedSegments = booking.getDisplayedSegments();
</script>

<style>.onwardFlightsWidget {
  margin-top: 1rem;
  width: 100%;
  margin-bottom: 1rem;
}</style>

<div class="onwardFlightsWidget">
  {#if displayedSegments && displayedSegments.length > 0}
    <div class="pb-1">
      <h3 class="border-gold-100 border-b-1 italic pb-2">
        {$_('app.onwardFlights')}
      </h3>
    </div>

    {#each displayedSegments as segment, i}
      <div class="flex items-center mt-1">
        <input id={`onward-flight-${i}`} type="radio" checked disabled />

        <label
          class="font-AltisMedium font-normal mb-0 text-base uppercase"
          for={`onward-flight-${i}`}
        >
          {`${$_(booking.getOriginCity(segment))} (${
            segment.departureCode
          }) – ${$_(booking.getDestinationCity(segment))} (${
            segment.arrivalCode
          })`}
        </label>
      </div>
    {/each}
  {/if}
</div>
