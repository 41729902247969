<script>
  import { _ } from 'svelte-i18n';
  import { onMount } from 'svelte';

  import { booking } from '../../../js/stores/booking';

  import plane from '../../../svg/plane.svg';

  export let segment = null;
  export let seatMapManager = null;

  let destinationCity = null;
  let originCity = null;

  $: equipmentName = seatMapManager ? seatMapManager.getEquipmentName() : '';

  onMount(() => {
    destinationCity = booking.getReactiveDestinationCity(segment);
    originCity = booking.getReactiveOriginCity(segment);
  });
</script>

<style>.destination {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  position: relative;
}</style>

<div class="destination">
  <div class="absolute flex inset-0 justify-center">
    <div class="absolute flex h-full items-center left-0 px-18 right-0">
      <div class="border-t-3 border-gold-100 mx-4 w-full" />
    </div>

    <div class="flex relative w-54">
      <div class="mx-auto text-gold-100 w-16">
        {@html plane}
      </div>
      <p
        class="absolute bottom-0 font-AltisMedium left-0 -mb-8 right-0
        text-center text-lg w-full"
      >
        {equipmentName}
      </p>
    </div>
  </div>

  <div class="bg-white leading-tight px-4 z-0">
    <p class="font-AltisMedium text-2xl">
      {segment ? segment.departureCode : ''}
    </p>
    <p class="text-lg whitespace-pre">
      {segment ? $originCity : ''}
    </p>
  </div>

  <div class="bg-white leading-tight px-4 z-0">
    <p class="font-AltisMedium text-2xl">
      {segment ? segment.arrivalCode : ''}
    </p>
    <p class="text-lg whitespace-pre">
      {segment ? $destinationCity : ''}
    </p>
  </div>
</div>
