<script>
  import { _ } from 'svelte-i18n';

  export let items = [];
  export let icon = '';

  $: iconListProps = {
    ...$$restProps,
    class: ['iconList', $$restProps.class].filter(Boolean).join(' '),
  };

  /**
   * IconList component.
   *
   * @example <caption>Example usage.</caption>
   * <IconList
   *   class="mb-12"
   *   icon={checkboxFilled}
   *   {items}
   * />
   *
   * @type {String} class.
   * @type {String} icon.
   */
</script>

<style>.iconList li {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.iconList li:last-child {
  margin-bottom: 0;
}</style>

<div {...iconListProps}>
  <ul>
    {#each items as item}
      <li>
        <div class="h-7 text-gold-100 w-7">
          {@html icon}
        </div>
        <p class="px-4 text-1.375">{$_(`${item.text}`)}</p>
      </li>
    {/each}
  </ul>
</div>
