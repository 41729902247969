<script>
  export let max = '100';
  export let value = '0';

  $: progressBarProps = {
    max,
    value,
    ...$$restProps,
    class: [$$restProps.class].filter(Boolean).join(' '),
  };

  /**
   * ProgressBar component.
   *
   * @example <caption>Example progress bar.</caption>
   *  <ProgressBar
   *    class="mb-2"
   *    max="{maxValue}"
   *    value="{currentValue}"
   *  </ProgressBar>
   *
   * @type {String} max. - maximum value (i.e. 100/100)
   * @type {String} value. - current value (i.e. 25/100)
   */
</script>

<style>progress {
  border-width: 1px;
  --border-opacity: 1;
  border-color: #bd8b13;
  border-color: rgba(189, 139, 19, var(--border-opacity));
  height: 0.75rem;
  width: 100%;
}

progress[value]::-webkit-progress-bar {
  background-color: transparent;
}

progress[value]::-webkit-progress-value {
  --bg-opacity: 1;
  background-color: #bd8b13;
  background-color: rgba(189, 139, 19, var(--bg-opacity));
}</style>

<progress {...progressBarProps} />
