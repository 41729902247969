<script>
  import { _ } from 'svelte-i18n';
  import { push } from 'svelte-spa-router';

  import {
    plusgradeAncillaryOfferManager,
    ProductNames,
    rightToLeft,
  } from '../../js/stores';

  import Button from './Button.svelte';

  export let heading = '';
  export let image = '';
  export let size = '';
  export let paragraph = '';
  export let imageRatio = '4:3';
  export let contentVerticalAlignment = 'center';

  $: advertisementProps = {
    role: 'button',
    ...$$restProps,
    class: [
      'advertisement',
      size === 'small' && 'advertisement--small',
      size === 'large' && 'advertisement--large',
      $$restProps.class,
    ]
      .filter(Boolean)
      .join(' '),
  };

  /**
   * Advertisement component.
   *
   * @example <caption>Example advertisement.</caption>
   *  <Advertisement
   *    contentVerticalAlignment="end"
   *    heading={$_('myCoolHeading')}
   *    image="myCoolImage"
   *    imageRatio="21:9"
   *    paragraph={$_('myCoolParagraph')}
   *    size="small"
   *    on:click={myCoolHandler} />
   *
   * @type {String} contentVerticalAlignment. - center, end, start.
   * @type {String} heading.
   * @type {String} image. - image for the advertisement.
   * @type {String} imageRatio. - image ratio for the advertisement.
   * @type {String} paragraph.
   * @type {Boolean} size. - small, large.
   *
   */
</script>

<style>.advertisement {
  width: 100%;
}

.advertisement__overlay {
  position: absolute;
  --bg-opacity: 1;
  background-color: #181818;
  background-color: rgba(24, 24, 24, var(--bg-opacity));
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
}

h4 {
  font-family: AltisMedium;
  margin-bottom: 0.25rem;
  font-size: 1.3rem;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

p {
  margin-bottom: 0.75rem;
  font-size: 1rem;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.advertisement__content {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  width: 66.666667%;
}

.advertisement__content-inner {
  line-height: 1.25;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
  text-align: left;
}

.advertisement--small h4 {
  margin-bottom: 0;
  font-size: 0.625rem;
}

.advertisement--small p {
  margin-bottom: 0;
  font-size: 0.5rem;
}

.advertisement--small .advertisement__content {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 75%;
}

.advertisement--small .advertisement__content-inner {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.advertisement--large h4 {
  font-size: 1.875rem;
}

.advertisement--large p {
  font-size: 1.25rem;
}

.advertisement--large .advertisement__content {
  width: 75%;
}

.advertisement--large .advertisement__content-inner {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}</style>

<button {...advertisementProps} on:click>
  <div class="relative">
    <div class="ratio ratio--{imageRatio}">
      <img
        class="ratio__content object-cover"
        src={`images/${image}.webp`}
        alt=""
      />
    </div>

    <div class="advertisement__content items-{contentVerticalAlignment}">
      <div class="relative">
        <div class="advertisement__overlay" />

        <div
          class="{$rightToLeft ? 'text-right' : 'text-left'}
          advertisement__content-inner"
        >
          <h4>{heading}</h4>
          <p>{paragraph}</p>
          <Button size={size === 'small' ? 'micro' : 'small'}>
            {$_('app.moreDetails')}
          </Button>
        </div>
      </div>
    </div>
  </div>
</button>
