<script>
  import Seat from './Seat.svelte';

  export let isOpen = null;
  export let passenger = null;
  export let passengerSeats = null;
  export let previewSeat = null;
  export let seatSpace = null;
  export let seatWidth = null;
  export let seats = null;
  export let selectedSeat = null;

  $: seatCount = (seats || []).length;
  $: seatsWidth = (seatWidth + seatSpace) * seatCount - seatSpace;
</script>

<style>.seatGroup {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}</style>

<div class="seatGroup" style="width: {seatsWidth}rem;">
  {#each seats as seat}
    <Seat
      bind:isOpen
      {passenger}
      {passengerSeats}
      {previewSeat}
      {seat}
      {selectedSeat}
      on:selectSeat
      on:unSelectSeat
      on:scrollToSeat
    />
  {/each}
</div>
