<script>
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher } from 'svelte';
  import { booking, rightToLeft } from '../../../js/stores';

  import checkCircle from '../../../svg/checkCircle.svg';

  export let flightNumber = null;
  export let passenger = null;
  export let selectedSeat = null;
  export let isCurrentlySelectedPassenger = false;

  $: isCurrentlySelectedPassenger

  const dispatch = createEventDispatcher();

  /** Handle a passenger change. */
  function passengerChangeHandler() {
    dispatch('passengerChange', {
      flightNumber,
      passenger,
    });
  }

</script>

<style>.passengerSelectItem {
  flex: none;
}

.passenger-box {
  max-width: 190px;
  margin: 14px;
  overflow: hidden;
  min-width: 190px;
}

.currentPaxItem {
  --bg-opacity: 1;
  background-color: #bd8b13;
  background-color: rgba(189, 139, 19, var(--bg-opacity));
  border-width: 2px;
  --border-opacity: 1;
  border-color: #bd8b13;
  border-color: rgba(189, 139, 19, var(--border-opacity));
}</style>

<li class="passengerSelectItem passenger-box">
  <button class="text-left" role="button" on:click={passengerChangeHandler}>
    <div
      class="{passenger.currentSeat === selectedSeat ? 'bg-charcoal-10' : ''}
      {$rightToLeft ? 'ml-6' : 'mr-6'} {isCurrentlySelectedPassenger ? 'currentPaxItem' : ''} inline-block leading-snug p-1
      whitespace-no-wrap"
    >
      <p class="block">
        {booking.getTranslatedTitle(passenger)}
        {#if $rightToLeft}
          {passenger.lastName} {passenger.firstName}
        {:else}
          {passenger.firstName} {passenger.lastName}
        {/if}
      </p>
      <div class="flex items-center">
        <p class="block font-AltisMedium">
          {$_('seatMap.seat')}
          {passenger.currentSeat || $_('app.notApplicable')}
        </p>
        {#if passenger.currentSeat}
          <div class="{$rightToLeft ? 'mr-1' : 'ml-1'} text-palmGreen w-5">
            {@html checkCircle}
          </div>
        {/if}
      </div>
    </div>
  </button>
</li>
