<script>
  import { createEventDispatcher } from 'svelte';
  import { hotkey } from '../../js/actions';
  import bag from '../../svg/bag.svg';

  export let numberOfBags = '';

  const dispatch = createEventDispatcher();
</script>

<style>.bagsButton {
  --bg-opacity: 1;
  background-color: #4e4c4a;
  background-color: rgba(78, 76, 74, var(--bg-opacity));
  font-family: AltisMedium;
  height: 7.25rem;
  border-radius: 0.25rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: 12rem;
}

.bagNumber {
  position: absolute;
  font-family: AltisMedium;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: 1.25rem;
  font-size: 1.625rem;
  --text-opacity: 1;
  color: #bd8b13;
  color: rgba(189, 139, 19, var(--text-opacity));
  z-index: 1;
}</style>

<button
  use:hotkey
  on:click={() => {
    dispatch('numberOfBagsSelection', { numberOfBags });
  }}
  class="bagsButton"
>
  <div class="relative">
    <p class="bagNumber">{numberOfBags}</p>
    <div class="mx-auto text-white w-18">
      {@html bag}
    </div>
  </div>
</button>
