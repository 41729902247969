<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { location, push } from 'svelte-spa-router';
  import { onMount, onDestroy } from 'svelte';

  import {
    ApplicationFlow,
    FeatureFlags,
    TRANSACTION_STARTED_TIMEOUT,
  } from '../../js/const';
  import {
    appIsActive,
    applicationFlow,
    featureFlags,
    persistentSingleApplicationMode,
    rightToLeft,
    transactionHasStarted,
    applicationMadeActiveThroughTransfer
  } from '../../js/stores';

  import flushState from '../../js/reset';
  import { sleep } from '../../js/utils';
  import { handleTransactionFinished } from '../../js/cuss-components';
  import logger from '../../js/logger';
  import resetTransport from '../../js/resetTransport';

  import Header from '../components/Header/index.svelte';
  import MRZHotkeys from '../components/MRZHotkeys.svelte';
  import PassportScanningFrame from '../components/PassportScanningFrame.svelte';
  import PrivacyPolicy from '../components/modal/PrivacyPolicy.svelte';
  import PrivacyPolicyText from '../components/modal/PrivacyPolicyText.svelte';
  import VerifiedToFly from '../components/VerifiedToFly.svelte';
  import Video from '../components/Video.svelte';

  const WAIT_FOR_TRANSPORT_RESET = 50; // milliseconds

  const hasCamera = featureFlags.isEnabled(FeatureFlags.CAMERA);
  const hasPcrTest = featureFlags.isEnabled(FeatureFlags.PCR_TEST);
  const isPorterBagDrop = ApplicationFlow.PORTER_BAG_DROP === get(applicationFlow);
  const isHybridFlow = ApplicationFlow.HYBRID === get(applicationFlow);
  const isSelfServiceFlow = ApplicationFlow.SELF_SERVICE === get(applicationFlow);

  $: shouldShowHomeExitButton = $applicationMadeActiveThroughTransfer === true && isSelfServiceFlow
  $: console.log(`Application made through transfer changed! Value is: ${$applicationMadeActiveThroughTransfer}`)

  let unsubscribe;
  let appActiveUnsubscribe;
  let transactionStartedTimeoutId = null;
  let showPrivacyPolicyModal = false;

  /**
   * Starts a timeout checking if there has been no transaction started in
   * Home screen. When the timeout occurs, consider this as
   * transaction-finished case.
   * This timeout does not apply when the application runs in PSAM.
   */
  function startTransactionStartedTimeout() {

    if (get(persistentSingleApplicationMode) === true) {
      logger.info(
        'App is running in PSAM.',
        'Do not start timeout check in Home screen.'
      );
      return;
    }

    logger.debug('Start transaction-started timeout check.');
    transactionStartedTimeoutId = setTimeout(() => {
      const isAppActive = get(appIsActive);
      const currentLocation = get(location);

      if (!(isAppActive === true && currentLocation === '/')) {
        logger.warn(
          'Transaction-started timeout occurs.',
          'Required condition to run transaction-finished logic' +
            ' is not satisfied.',
          `appIsActive=${isAppActive}, currentLocation=${currentLocation}`
        );
        return;
      }

      logger.info(
        'Transaction-started timeout occurs.',
        'Consider this as transaction-finished.'
      );
      handleTransactionFinished();
    }, TRANSACTION_STARTED_TIMEOUT * 1000);
  }

  /**
   * Stops the timeout used to check if there has been no transaction started
   * in Home screen.
   */
  function stopTransactionStartedTimeout(reason) {
    if (transactionStartedTimeoutId === null) {
      return;
    }

    logger.debug(`Stop transaction-started timeout check. Reason: ${reason}.`);
    clearTimeout(transactionStartedTimeoutId);
    transactionStartedTimeoutId = null;
  }

  /** Show Privacy Policy modal. */
  function privacyPolicyHandler() {
    showPrivacyPolicyModal = true;
  }

  onMount(() => {
    const transactionHasStartedSubscription = (hasStarted) => {
      if (hasStarted) {
        stopTransactionStartedTimeout('Transaction has started');
        push('/searching/true');
      }
    };
    const appIsActiveSubscription = (isActive) => {      
      logger.info(`Home screen sees appIsActive: ${isActive}.`);
      logger.info(`Home screen sees should show home button ${shouldShowHomeExitButton}.`);
      logger.info(`Home screen sees app active through transfer: ${get(applicationMadeActiveThroughTransfer)}.`);
      logger.info(`Home screen sees self service flow: ${isSelfServiceFlow}`);

      if (isActive) {
        startTransactionStartedTimeout();
      } else {
        stopTransactionStartedTimeout('App is not Active');
      }
    };

    resetTransport();
    sleep(WAIT_FOR_TRANSPORT_RESET).then(() => {
      flushState();
      unsubscribe = transactionHasStarted.subscribe(
        transactionHasStartedSubscription
      );
      appActiveUnsubscribe = appIsActive.subscribe(appIsActiveSubscription);
    });
  });

  onDestroy(() => {
    unsubscribe();
    stopTransactionStartedTimeout('Home component no longer exists.');
    appActiveUnsubscribe();
  });
</script>

<style>.debug {
  position: fixed;
  top: 10px;
  right: 20px;
  background: none;
  cursor: pointer;
}

.home {
  margin-top: -2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3.25rem;
  padding-right: 3.25rem;
  height: calc(100vh - 6.75rem); /* -108px header height */
}</style>

<PrivacyPolicy bind:showModal={showPrivacyPolicyModal} />

<!-- EYSS-3378 Only show exit button on home page if multi-application mode (eg, biometrics) -->
<Header logoWidth="w-68" hasHomeButton={shouldShowHomeExitButton} immediateTransactionEnd={true} />

<div class="home">
  <div class="mb-14" class:-mt-12={hasCamera}>
    <p
      class="italic px-14 text-3.75 text-gold-100 {$rightToLeft
        ? 'text-right'
        : 'text-left'}"
    >
      {$_('home.getStarted')}
    </p>
    <h1 class="text-center">
      {#if hasCamera}{$_('home.headingCamera')}{:else}{$_('home.heading')}{/if}
    </h1>
  </div>

  {#if isPorterBagDrop}
    {#if $appIsActive && hasCamera}
      <div class="flex justify-center mb-12 w-full">
        <PassportScanningFrame class="mx-12" />
      </div>
    {:else}
      <div class="mx-18 my-10 relative">
        {#if hasPcrTest}
          <div class="absolute top-0 left-0 -mt-12 -ml-12 z-5">
            <VerifiedToFly />
          </div>
        {/if}

        <div class="ratio">
          <img
            alt="Passport scan demonstration."
            class="ratio__content"
            src="images/porterBagDropPassportScan.webp"
          />
        </div>
      </div>
    {/if}
  {:else if isHybridFlow} 
    <div class="mx-40 my-10 relative">
      {#if hasPcrTest}
        <div class="absolute top-0 left-0 -mt-12 -ml-12 z-5">
          <VerifiedToFly />
        </div>
      {/if}

      <Video ratio="9:16" src="selfServicePassportScanNew" />
    </div>
  {:else} 
    <div class="mx-40 my-10 relative">
      {#if hasPcrTest}
        <div class="absolute top-0 left-0 -mt-12 -ml-12 z-5">
          <VerifiedToFly />
        </div>
      {/if}

      <Video ratio="9:16" src="selfServicePassportScanNew" />
    </div>
  {/if}

  <div class="absolute bottom-0 inset-x-0 mb-20">
    <PrivacyPolicyText {privacyPolicyHandler} />
  </div>
</div>

<MRZHotkeys />
