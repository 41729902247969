<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onMount } from 'svelte';
  import bag from '../../svg/bag.svg';
  import {
    booking,
    flightDate,
    pnr,
    rightToLeft,
  } from '../../js/stores';

  import GuestTier from '../components/GuestTier.svelte';
  import OnwardFlightsWidget from '../components/modal/OnwardFlightsWidget.svelte';

  import dot from '../../svg/dot.svg';
  import plane from '../../svg/plane.svg';

  let destinationCity = null;
  let originCity = null;
  let baggageUnit = booking.getReactiveBaggageUnit();

  const passenger = get(booking)?.passengers[0]; // taking the first passenger that is the head

  onMount(() => {
    destinationCity = booking.getReactiveDestinationCity();
    originCity = booking.getReactiveOriginCity();
  });
</script>

<style>.bookingSummarySingle {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5rem;
}

.vertical-line {
  height: 80%;
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.bagNumber {
  position: absolute;
  font-family: AltisMedium;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: 1.25rem;
  font-size: 1.625rem;
  --text-opacity: 1;
  color: #bd8b13;
  color: rgba(189, 139, 19, var(--text-opacity));
  z-index: 1;
}

.bagNumberPosition {
  text-align: center;
  margin-top: 75px;
}</style>

<div class="bookingSummarySingle">
  <div class="w-full text-center uppercase">
    <div class="flex font-AltisMedium">
      <p
        class="leading-tight text-gold-100 text-4xl text-{$rightToLeft
          ? 'right'
          : 'left'}"
      >
        {$originCity}
      </p>

      <div class="flex-1 flex items-center mx-4">
        <div class="w-12 text-charcoal-100">
          {@html plane}
        </div>

        <div class="flex-1 h-px border-b border-current border-dotted" />

        <div class="w-3">
          {@html dot}
        </div>
      </div>

      <p
        class="leading-tight text-gold-100 text-4xl text-{$rightToLeft
          ? 'left'
          : 'right'}"
      >
        {$destinationCity}
      </p>
    </div>

    <div class="flex items-center leading-tight">
      <div class="flex-none text-{$rightToLeft ? 'right' : 'left'}">
        <p>{$_('welcome.departureTime')}</p>
        <p>
          {$booking.departureTime
            ? $booking.departureTime
            : $_('unavailable.heading')}
        </p>
      </div>

      <div class="flex-auto" />

      <div class="flex-none text-{$rightToLeft ? 'left' : 'right'}">
        <p>{$_('welcome.arrivalTime')}</p>
        <p>
          {$booking.arrivalTime
            ? $booking.arrivalTime
            : $_('unavailable.heading')}
        </p>
      </div>
    </div>
  </div>

  <OnwardFlightsWidget />

  <div class="uppercase w-full">
    <div class="leading-tight">
      <div class="flex items-end">
        <div class={$rightToLeft ? 'ml-5' : 'mr-5'}>
          <p class="font-AltisMedium">{$_('welcome.passengerName')}</p>
          <p>{passenger?.lastName}, {passenger?.firstName}</p>
        </div>
        {#if booking.getFrequentFlyerTierName(passenger)}
          <GuestTier tier={booking.getFrequentFlyerTierName(passenger)} />
        {/if}
      </div>
    </div>

    <div class="flex justify-between -mx-2 mt-8">
      <div class="leading-tight px-2">
        <p class="font-AltisMedium uppercase">{$_('welcome.flightDate')}</p>
        <p>{$flightDate}</p>
      </div>

      <div class="leading-tight px-2">
        <p class="font-AltisMedium uppercase">{$_('welcome.flightNumber')}</p>
        <p>
          {booking.getDisplayedFlightCode()}
        </p>
      </div>

      <div class="leading-tight px-2">
        <p class="font-AltisMedium uppercase">
          {$_('welcome.bookingReference')}
        </p>
        <p>{$pnr}</p>
      </div>

      <div class="leading-tight px-2">
        <p class="font-AltisMedium uppercase">{$_('welcome.seat')}</p>
        <p>{booking.getSeatNumber(passenger) || $_('app.notApplicable')}</p>
      </div>

      <div class="-mt-14">
        <div class="relative">
          <p class="bagNumber bagNumberPosition">{`${booking.totalCombinedAllowance()} ${$baggageUnit}`}</p>
          <div class="w-34 mx-auto text-white ">
            {@html bag}
          </div>    
        </div>        
      </div>
    </div>
  </div>
</div>

<div class="vertical-line absolute bg-gold-100 w-px hidden" />
