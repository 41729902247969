<script>
  import prettyPrintJson from 'pretty-print-json';
  export let json = {};
  export let heading = '';
</script>

<style>pre {
  background-color: mintcream;
  border: 1px solid silver;
  padding: 10px 20px;
}

pre :global(.json-key) {
  color: brown;
}

pre :global(.json-value) {
  color: navy;
}

pre :global(.json-boolean) {
  color: teal;
}

pre :global(.json-string) {
  color: olive;
}</style>

<div class="my-8">
  <div class="uppercase text-xs">{heading}</div>
  <pre class="overflow-y-auto h-64">
    {@html prettyPrintJson.toHtml(json)}
  </pre>
</div>
