<script>
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import { derived } from 'svelte/store';
  import { get } from 'svelte/store';
  import { location } from 'svelte-spa-router';
  import {
    booking,
    headPassengerManager,
    passportNumbers,
    rightToLeft,
    userInitiationMethod,
  } from '../../js/stores';
  import { checkInPassengers } from '../../js/stores/checkInPassengers';
  import { isObjEmpty } from '../../js/utils';
  import { TransactionInitiator, AllowanceType } from '../../js/const';
  import { VoiceIntent } from '../../js/services/voicerec/voicerec';
  import GuestTier from '../components/GuestTier.svelte';
  import Button from '../components/Button.svelte';
  import checkCircle from '../../svg/checkCircle.svg';
  import infantIcon from '../../svg/infant.svg';
    
  export let passenger = {};
  export let currentPassengerIndex;
  export let showCheckCircle = true;

  // this passengers list is returned from headpassengers.sortPassengers
  // which is also used in the display of passengers on Passport to Camera
  // so we ensure that currentPassengerIndex which just tells which index
  // in sortedPAssengers array is currently the passenger being scanned or active passenger
  export let passengers = [];
  
  const screenName = '/scan-passport';
  const translate = get(_);

  let adultScanned;
  let hasInfant;
  let infant;
  let infantScanned;
  let unsubscribe = () => {};
  let useAdditionalAllowance;
  let isGuidedFlow;

  /** reactivity for paginated items. */
  $: adultScanned =
    wasHeadPassengerWithPassport() ||
    passportNumbers.passengerIsScanned(passenger);
  $: hasInfant = !isObjEmpty(infant || {});
  //$: infant = booking.getInfantByID(passenger.passengerID);
  $: infant = booking.getInfantByID(passenger.infantPassengerID);  //in amadeus the field that matches 
  $: infantScanned = passportNumbers.passengerIsScanned(infant);
  $: passengerData = {
    adultName: displayPassengerName(passenger, $rightToLeft),
    adultSeatNumber:
      booking.getSeatNumber(passenger) || translate('app.notApplicable'),
    infantName: `${infant.lastName}/${infant.firstName}`,
    infantSeatNumber: translate('app.notApplicable'),
    tierName: booking.getFrequentFlyerTierName(passenger),
    totalPassengerAllowance: totalPassengerAllowance(),
  };

  $: isCurrentPassenger = currentPassengerIndex === getIndexInSortedPassengers();

  function displayPassengerName(passenger, directionIsRightToLeft)
  {
    // If no title is returned, do not show it
    if(directionIsRightToLeft)
    {
      if(booking.getTranslatedTitle(passenger))
      {
        return `${booking.getTranslatedTitle(passenger)}/${passenger.lastName} ${passenger.firstName}`
      }
      return `${passenger.lastName} ${passenger.firstName}`
    }
    else
    {
      if(booking.getTranslatedTitle(passenger))
      {
        return `${passenger.firstName} ${passenger.lastName}/${booking.getTranslatedTitle(passenger)}`
      }
      return `${passenger.lastName} ${passenger.firstName}`
    }
  }

  function getIndexInSortedPassengers() {
    const index = passengers.map(function(e) { return e.passengerID; }).indexOf(passenger.passengerID);
    return index;
  }
  
  const dispatch = createEventDispatcher();
  function skipPassenger() {    
    dispatch('skipPassenger');
  }

  /**
   * Subscribe to passportNumbers and checkInPassengers as a derived store.
   *
   * Used to determine whether or not the "green tick" should appear
   * next to a passenger's name (eg. setting adultScanned and infantScanned).
   *
   * Creates a derived store. Note there are two callbacks here. The callback
   * of the derived store, and the callback of the subscription to the
   * derived store. The first is invoked whenever either of the parent stores
   * change value. The second is invoked whenever result of the first callback
   * is different. I have made the first callback return new Object(), so that
   * the second callback is guaranteed to run following the first.
   *
   * The second callback, ie. the subscription callback, performs the logic
   * to determine whether or not green ticks should be applied. It needs to
   */
  function subscribe() {
    unsubscribe = derived(
      [passportNumbers, checkInPassengers],
      () => new Object(),
    ).subscribe(() => {
      adultScanned =
        wasHeadPassengerWithPassport() ||
        passportNumbers.passengerIsScanned(passenger);
      infantScanned = passportNumbers.passengerIsScanned(infant);
    });
  }

  /**
   * Check transaction start method for head passenger.
   *
   * If the passenger of this svelte component is
   * the head passenger, return true if they started
   * the transaction by scanning their passport.
   *
   * @returns {boolean}
   */
  function wasHeadPassengerWithPassport() {
    return (
      get(userInitiationMethod) == TransactionInitiator.PASSPORT &&
      headPassengerManager.passengerIsHead(passenger)
    );
  }

  /**
   * Calculate total Passenger Allowance + Infant Allowance if Infant Available
   */
  function totalPassengerAllowance() {
    if (hasInfant) {
      return (
        Number(booking.passengerTotalAllowance(passenger)) +
        Number(booking.passengerTotalAllowance(infant))
      );
    } else {
      return Number(booking.passengerTotalAllowance(passenger));
    }
  }

  onMount(() => {
    isGuidedFlow = booking.hasUncheckedInPassengers();

    useAdditionalAllowance = booking.passengerAllowanceByType(
      passenger,
      AllowanceType.EMD,
    );

    subscribe();
  });

  // cleanup the subscribe
  onDestroy(unsubscribe);

</script>

<style>.passengerSummaryItem {
  border-bottom-width: 1px;
  --border-opacity: 1;
  border-color: #bd8b13;
  border-color: rgba(189, 139, 19, var(--border-opacity));
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.8rem;
  width: 100%;
}

.passenger-name {
  width: 50%;
}

.guest-not-here {
  width: 30%;
}

.check-icon {
  width: 10%;
}

.current-passenger-background {
  background-color: lightgoldenrodyellow;
}</style>

{#if passenger.passengerType !== 'INFANT'}
  <div
    class="passengerSummaryItem {$rightToLeft ? 'text-right' : 'text-left'} {(isCurrentPassenger) ? 'current-passenger-background' : null}"
  >
    <div class="my-1 passenger-name">
      <div class="mr-2" style="display: inline-flex">
        <p          
          class="truncate text-1.375 uppercase {hasInfant
            ? 'text-gold-100'
            : ''}"
        >
          {passengerData.adultName } 
        </p>

        {#if get(location) === screenName && passengerData.tierName}
          <GuestTier tier={booking.getFrequentFlyerTierName(passenger)} />
        {/if}

        {#if get(location) === screenName && passengerData.adultSeatNumber && passengerData.adultSeatNumber !== 'N/A'}
          <div class="text-1.375 leading-tight px-5">
            {'SEAT - ' + passengerData.adultSeatNumber}
          </div>            
        {/if}       
      </div>

      {#if hasInfant}
        <div class="flex items-center">
          <div class="w-10 mr-1 text-gold-100">
            {@html infantIcon}
          </div>
          <p class="pt-1 text-1.375 uppercase">
            {passengerData.infantName} 
          </p>
        </div>
      {/if}
    </div>

    {#if isGuidedFlow &&
         !booking.isSinglePassengerTransaction(true) &&
         isCurrentPassenger &&
         passenger.passengerType !== 'INFANT'}
      <div class="text-center guest-not-here mt-2 mb-2">
        <Button size="small" intent={VoiceIntent.SKIP} on:click={skipPassenger}>
          {$_('passportToCamera.guestNotHere')}
        </Button>
      </div>
    {/if}
    
    {#if showCheckCircle}
      <div class="my-1 ">
        <div
          class="w-12 mx-auto {adultScanned
            ? 'text-palmGreen'
            : 'text-charcoal-10'}"
        >
          {@html checkCircle}
        </div>
        {#if hasInfant}
          <div
            class="w-12 mt-1 mx-auto {infantScanned
              ? 'text-palmGreen'
              : 'text-charcoal-10'}"
          >
            {@html checkCircle}
          </div>
        {/if}
      </div>
    {/if}
  </div>
{/if}
