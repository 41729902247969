<script>
  import { onMount } from 'svelte';

  import cussBridge from '../../js/cuss-bridge';
  import { randomString } from '../../js/utils';

  onMount(() => {
    window.cussBridge = cussBridge;
  });

</script>

<style>applet {
  width: 0px;
  height: 0px;
  float: left;
}</style>

<applet
  name="cussapplet"
  codebase="applet"
  code="cussapplet.Applet.class"
  archive="cuss-applet-0.0.1-SNAPSHOT.jar?{randomString(32)}"
  width="0"
  height="0"
/>
