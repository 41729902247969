<script>
  import Exits from './Exits.svelte';
  import Row from './Row.svelte';
  import RowFacilities from './RowFacilities.svelte';

  export let cabinHeight = null;
  export let cabinWidth = null;
  export let isOpen = null;
  export let passenger = null;
  export let passengerSeats = null;
  export let previewSeat = null;
  export let seatMapManager = null;
  export let seatSpace = null;
  export let seatWidth = null;
  export let selectedSeat = null;
  export let spacerWidth = null;

  $: rows = seatMapManager ? seatMapManager.getRows() : [];
</script>

<style>.cabin {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2.75rem;
}</style>

<div
  class="cabin"
  bind:offsetHeight={cabinHeight}
  style="width: {cabinWidth}rem;"
>
<!-- In the Amadeus model, each row comes back as a distinct row and no facilies are linked to seat rows. -->
  {#each rows as row}
    {#if seatMapManager ? seatMapManager.isExitRow(row) : null}
      <Exits />
    <!-- Only show facilities if the whole row is facility row -->
    <!-- Implication is that rows now has facilities as well as standalones -->
    {:else if seatMapManager ? seatMapManager.isFacilityRow(row) : null}
        <RowFacilities {row} {seatMapManager} />
    {:else}
      <Row
        bind:isOpen
        {passenger}
        {passengerSeats}
        {previewSeat}
        {row}
        {seatMapManager}
        {seatSpace}
        {seatWidth}
        {selectedSeat}
        {spacerWidth}
        on:selectSeat
        on:unSelectSeat
        on:scrollToSeat
      />
    {/if}
  {/each}
</div>
