<script>
  import { _ } from 'svelte-i18n';

  import {
    plusgradeAncillaryOfferManager,
    ProductNames,
  } from '../../../js/stores';

  import Item from './Item.svelte';

  export let isSelected = false;
</script>

<style>.tripExtrasItems {
  margin-bottom: 1.5rem;
}</style>

<div class="tripExtrasItems">
  {#each plusgradeAncillaryOfferManager.getMatchingProducts([
    ProductNames.BUSINESS
  ]) as product}
    <Item bind:isSelected {product} />
  {/each}
</div>
