<script>
  import { _ } from 'svelte-i18n';

  import Available from './seats/Available.svelte';
  import Bar from './seats/Bar.svelte';
  import Bassinet from './seats/Bassinet.svelte';
  import EconomySpace from './seats/EconomySpace.svelte';
  import EconomySpaceSelected from './seats/EconomySpaceSelected.svelte';
  import EmergencyExit from './seats/EmergencyExit.svelte';
  import Galley from './seats/Galley.svelte';
  import Lavatory from './seats/Lavatory.svelte';
  import RearSeat from './seats/RearSeat.svelte';
  import Selected from './seats/Selected.svelte';
  import SpecialNeeds from './seats/SpecialNeeds.svelte';
  import Stairs from  './seats/Stairs.svelte';
  import Unavailable from './seats/Unavailable.svelte';

  import chevronDown from '../../../svg/chevronDown.svg';
  import chevronUp from '../../../svg/chevronUp.svg';

  export let seatMapManager = null;

  $: SEATS = [
    {
      seatType: Available,
      name: 'available',
      show: true,
    },
    {
      seatType: EconomySpace,
      name: 'economySpace',
      show: seatMapManager ? seatMapManager.isEconomyClass() : null,
    },
    {
      seatType: EconomySpaceSelected,
      name: 'economySpaceSelected',
      show: seatMapManager ? seatMapManager.isEconomyClass() : null,
    },
    {
      seatType: Selected,
      name: 'selected',
      show: true,
    },
    {
      seatType: Unavailable,
      name: 'unavailable',
      show: true,
    },
    {
      seatType: RearSeat,
      name: 'rear',
      seat: true,
      show: seatMapManager ? seatMapManager.hasRearFacingSeats() : null,
    },
    {
      seatType: Bassinet,
      name: 'bassinet',
      show: true,
    },
    {
      seatType: Lavatory,
      name: 'lavatory',
      show: true,
    },
    {
      seatType: Galley,
      name: 'galley',
      show: true,
    },
    {
      seatType: Bar,
      name: 'bar',
      show: true
    }
    ,
    {
      seatType: Stairs,
      name: 'stairs',
      show: true
    }
  ];

  let isExpanded = false;

  function expandHandler() {
    isExpanded = !isExpanded;
  }
</script>

<style>.legend {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.chevron {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-width: 1px;
  display: flex;
  height: 1.25rem;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
  border-radius: 9999px;
  --text-opacity: 1;
  color: #bd8b13;
  color: rgba(189, 139, 19, var(--text-opacity));
  width: 1.25rem;
}</style>

<div class="legend" on:click={expandHandler}>
  <div class="relative bg-charcoal-10 border-charcoal-10 px-3 rounded-sm">
    {#if isExpanded}
      <div class="absolute top-0 right-0">
        <div class="chevron mt-2">
          <div class="w-4">
            {@html chevronUp}
          </div>
        </div>
      </div>

      <div class="flex flex-wrap -mx-1 pr-10">
        {#each SEATS as seat, i}
          {#if seat.show}
            <div class="flex items-center px-1 py-2">
              <div class="w-7">
                <svelte:component this={seat.seatType} />
              </div>
              <p
                class="font-AltisMedium leading-tight mx-1 text-0.625
                whitespace-pre-line"
              >
                {$_(`seatMap.${seat.name}`)}
              </p>
            </div>
          {/if}
        {/each}
      </div>
    {:else}
      <div class="flex items-center">
        <h3 class="pr-1 text-xs">{$_('seatMap.legend')}</h3>
        <div class="chevron">
          <div class="w-4">
            {@html chevronDown}
          </div>
        </div>
      </div>
    {/if}
  </div>
</div>
