<script>
  import { _ } from 'svelte-i18n';
  import { onMount } from 'svelte';
  import bag from '../../../svg/bag.svg';
  import { AllowanceType } from '../../../js/const';
  import { booking, headPassenger, rightToLeft, passengerBagCounts } from '../../../js/stores';
  import { ButtonKinds } from '../../components/Button.svelte';
  import { push } from 'svelte-spa-router';

  import BagsButton from '../BagsButton.svelte';
  import Briefcase from '../Briefcase.svelte';
  import Button from '../Button.svelte';

  import handBaggage from '../../../svg/handBaggage.svg';
  import info from '../../../svg/info.svg';

  import { ApplicationStep, appReport, EventStatus, EventType, USER_ACTIONS } from '../../../js/appReport';
    import logger from '../../../js/logger';

  export let avgBags = null;
  export let cabinBaggageAllowanceHandler = null;
  export let showOtherNumberOfBagsModal = null;
  export let showOtherNumberOfBagsHandler = null;

  let baggageUnit = booking.getReactiveBaggageUnit();
  let isPcBaggage = null;

  onMount(() => {
    baggageUnit = booking.getReactiveBaggageUnit();
    isPcBaggage = booking.getBaggageUnit() === 'pc'; // detects if the current transaction is piece or weight concept
  });

  function noBagsClicked() {
    logger.info('User selects No Bag to check in');
    
    let eventDetails = { numberOfBags: 0, action: USER_ACTIONS.NUMBER_BAGS_SELECTED }

    appReport.updateStepWithEventDetails(
      ApplicationStep.ACCEPT_BAGGAGE_GROUP,
      EventType.STEP_IN_PROGRESS,
      EventStatus.SUCCESS,
      eventDetails
    );

    appReport.updateStepSuccess(ApplicationStep.ACCEPT_BAGGAGE_GROUP)

    passengerBagCounts.setPassengerBagCount(
      $headPassenger.passengerID,
      0,
    );

    push('/dangerous-goods');
  }

</script>

<style>.more-bags {
  width: 380px;
  max-width: 380px;
}

.no-bags {
  width: 380px;
  max-width: 380px;
}

.button-space {
  margin-right: 2vw;
}

.bagNumber {
  position: absolute;
  font-family: AltisMedium;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: 1.25rem;
  font-size: 1.625rem;
  --text-opacity: 1;
  color: #bd8b13;
  color: rgba(189, 139, 19, var(--text-opacity));
  z-index: 1;
}

.bagNumberPosition {
  text-align: center;
  margin-top: 75px;
}</style>

<div class="flex justify-center mt-14 mb-9">
  <BagsButton
    numberOfBags={avgBags - 1}
    on:numberOfBagsSelection={showOtherNumberOfBagsHandler}
  />
  <BagsButton
    numberOfBags={avgBags}
    on:numberOfBagsSelection={showOtherNumberOfBagsHandler}
  />
  <BagsButton
    numberOfBags={avgBags + 1}
    on:numberOfBagsSelection={showOtherNumberOfBagsHandler}
  />
</div>

<div class="flex justify-center mb-20 mx-12">
  <div class="more-bags {$rightToLeft ? '' : 'button-space'}">
    <Button
      isFull
      kind={ButtonKinds.SECONDARY}
      size="large"
      on:click={() => {
        showOtherNumberOfBagsModal = true;
      }}    
    >
      {$_('numberOfBags.moreBags')}
    </Button>
  </div>

  <div class="no-bags {$rightToLeft ? 'button-space' : ''} ">
    <Button
      isFull
      kind={ButtonKinds.SECONDARY}
      size="large"
      on:click={noBagsClicked}    
    >
      {$_('numberOfBags.noBags')}
    </Button>
  </div>
</div>

<h3 class="border-b border-gray-500 italic mt-6 mb-12 pb-2">
  {$_('baggageAllowance.heading')}
</h3>

<div class="flex items-center mb-20">

  <div class="relative">
    <p class="bagNumber bagNumberPosition">{`${booking.totalCombinedAllowance()} ${$baggageUnit}`}</p>
    <div class="w-34 mx-auto text-white ">
      {@html bag}
    </div>    
  </div>

  <div class="flex-1 mx-8">
    {#if booking.totalAllowanceByType(AllowanceType.VCR) > 0}
      <h3 class="mb-1">
        <span
          >{booking.totalAllowanceByType(AllowanceType.VCR) +
            booking.totalAllowanceByType(AllowanceType.PGCU)}
          {$baggageUnit}</span
        >
        {$_('numberOfBags.checkInBaggageAllowance')}
      </h3>
    {/if}

    {#if booking.totalAllowanceByType(AllowanceType.FF) > 0}
      <h3 class="mb-1">
        + <span
          >{booking.totalAllowanceByType(AllowanceType.FF)}
          {$baggageUnit}</span
        >
        {$_('app.frequentFlyer')}
      </h3>
    {/if}

    {#if booking.totalAllowanceByType(AllowanceType.EMD) > 0}
      <h3>
        + <span
          >{booking.totalAllowanceByType(AllowanceType.EMD)}
          {$baggageUnit}</span
        >
        {$_('app.prepaid')}
      </h3>
    {/if}
    
    {#if !isPcBaggage && booking.totalWeightUsedInPreviousTransaction() > 0}        
      <h3>      
          - <span
            >{booking.totalWeightUsedInPreviousTransaction()}
            {$baggageUnit}</span
          > {$_('numberOfBags.baggageAllowanceAlreadyUsed')}
      </h3>            
    {/if}  
    
    {#if isPcBaggage && booking.totalNumberOfActivatedBagTags() > 0}
      {#if booking.totalNumberOfActivatedBagTags() >= booking.totalCombinedAllowance()}
          <h3>
            - <span
                >{booking.totalCombinedAllowance()}
                {$baggageUnit}</span
              > {$_('numberOfBags.baggageAllowanceAlreadyUsed')}
          </h3>
       {:else} 
          <h3>
            - <span
                >{booking.totalNumberOfActivatedBagTags()}
                {$baggageUnit}</span
              > {$_('numberOfBags.baggageAllowanceAlreadyUsed')}
          </h3>
       {/if}
    {/if}

    {#if !isPcBaggage && booking.totalWeightUsedInPreviousTransaction() > 0}
      <h3>
        <span
            >{booking.totalRemainingAllowance()}
            {$baggageUnit}</span
          >
          {$_('numberOfBags.remainingBaggageAllowance')}      
      </h3>
    {:else if booking.totalNumberOfActivatedBagTags() === 0}
      <h3>
        <span>{booking.totalCombinedAllowance()} 
          {$baggageUnit}</span>
          {$_('numberOfBags.remainingBaggageAllowance')}
      </h3> 
    {/if}
    
    {#if isPcBaggage}
      {#if booking.totalNumberOfActivatedBagTags() > 0 && booking.totalNumberOfActivatedBagTags() <= booking.totalCombinedAllowance()}
        <h3>
          <span
              >{booking.totalCombinedAllowance() - booking.totalNumberOfActivatedBagTags()}
              {$baggageUnit}</span
            >
            {$_('numberOfBags.remainingBaggageAllowance')}
        </h3>      
      {:else if booking.totalNumberOfActivatedBagTags() > 0 && booking.totalNumberOfActivatedBagTags() > booking.totalCombinedAllowance()}
        <h3>
          <span> 0 {$baggageUnit}</span>
            {$_('numberOfBags.remainingBaggageAllowance')}
        </h3>
      {/if}
    {/if}


    {#if isPcBaggage && booking.totalCombinedAllowance() - booking.totalNumberOfActivatedBagTags() < 0}
      <h3>
        <span
            >{booking.totalNumberOfActivatedBagTags() - booking.totalCombinedAllowance()}
            {$baggageUnit}</span
          >
          {$_('numberOfBags.excessPiecesUsed')}
      </h3>
    {/if}

  </div>
</div>

<button class="bg-informative-100" on:click={cabinBaggageAllowanceHandler}>
  <div class="flex items-center px-2 py-4">
    <div class="-mt-3 -mx-1 w-24">
      {@html handBaggage}
    </div>

    <p class="text-1.375">
      {$_('cabinBaggageAllowance.cabinBaggageAllowance')}
    </p>

    <div class="mx-3 text-informative-200 w-10">
      {@html info}
    </div>
  </div>
</button>
