<script>
  export let cabinWidth = null;
  export let seatSpace = null;
  export let seatWidth = null;
  export let hasScrollbar = false;
  export let seatMapManager = null;
  export let spacerWidth = null;

  $: columnGroups = seatMapManager
    ? seatMapManager.getGroupsForColumnHeadings()
    : [];
</script>

<style>.columnHeadings {
  padding-top: 0.5rem;
}

.group {
  display: flex;
  justify-content: space-around;
  padding-bottom: 0.5rem;
  padding-top: 0.25rem;
}

.heading {
  font-family: AltisMedium;
  text-align: center;
  font-size: 1.25rem;
}

.rowSpacer:last-child {
  display: none;
}

.hasScrollbar {
  padding-right: 2.5rem;
}</style>

<div class="columnHeadings" class:hasScrollbar>
  <div class="flex justify-center mx-auto" style="width: {cabinWidth}rem;">
    {#each columnGroups as columnGroup}
      <div
        class="group"
        style="margin-left: -{seatSpace / 2}rem; margin-right: -{seatSpace / 2}rem;">
        {#each columnGroup as columnName}
          <div class="heading" style="width: {seatWidth + seatSpace}rem;">
            {columnName}
          </div>
        {/each}
      </div>
      <div class="rowSpacer" style="width: {spacerWidth}rem;" />
    {/each}
  </div>
</div>
