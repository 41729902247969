<script>
  import { _ } from 'svelte-i18n';

  export let heading = null;

  /**
   * PassengerSummaryHeadings component.
   *
   * @example <caption>Example usage.</caption>
   *  <PassengerSummaryHeadings
   *    {passengers}
   *    heading="heading"
   *  />
   *
   * @type {String} heading. - the first heading in the row
   */
</script>

<style>.passengerSummaryHeadings {
  border-bottom-width: 1px;
  --border-opacity: 1;
  border-color: #bd8b13;
  border-color: rgba(189, 139, 19, var(--border-opacity));
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}</style>

<div class="passengerSummaryHeadings">
  <div class="w-2/5">
    <h3 class="italic">
      {#if heading}
        {$_(`passengerSummaryHeadings.${heading}`)}
      {:else}
        {$_('passengerSummaryHeadings.passengers')}
      {/if}
    </h3>
  </div>
</div>
